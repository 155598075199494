import { Box, Text } from '@mantine/core';
import { FileWithPath } from '@mantine/dropzone';
interface XhtmlPreviewProps {
  files: FileWithPath[];
}
/**
 * XhtmlPreviews is a functional component that renders previews of files in XHTML format.
 *
 * @param {XhtmlPreviewProps} props - The props of the component.
 * @param {FileWithPath[]} props.files - An array of files to be previewed.
 *
 * @returns {JSX.Element} - Renders the previews of the files in XHTML format.
 *
 * @example
 * <XhtmlPreviews files={[file1, file2]} />
 */
function XhtmlPreviews(props: XhtmlPreviewProps) {
  const { files } = props;
  if (props.files.length === 0) {
    return (
      <Box
        style={{ width: '100%', height: '100%' }}
        sx={(theme) => ({
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
          padding: theme.spacing.xs,
          borderRadius: theme.radius.md
        })}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}>
          <Text> No files uploaded. Preview will be shown here. </Text>
        </div>
      </Box>
    );
  }
  const previews = files.map((file: Blob | MediaSource, index: number) => {
    return (
      <object
        style={{ width: '100%', height: '100%' }}
        data={URL.createObjectURL(file)}
        key={index}
        type="application/xhtml+xml"
      />
    );
  });
  return <>{previews}</>;
}

export default XhtmlPreviews;
