import { Grid } from '@mantine/core';
import UploadDropzone from './UploadDropzone/UploadDropzone';
import { useState } from 'react';
import { FileWithPath } from '@mantine/dropzone';
import XhtmlPreviews from './XhtmlPreviews/XhtmlPreviews';

const initialCompanyData: CompanyData = {
  companyName: '',
  companyId: '',
  revenue: {
    currentYear: 0,
    previousYear: 0
  },
  growth: 0
};

function MainContent() {
  const [companyData, setCompanyData] = useState<CompanyData>(initialCompanyData);
  const [uploadedFiles, setUploadedFiles] = useState<FileWithPath[]>([]);
  return (
    <Grid>
      <Grid.Col lg={6} md={12} style={{ padding: '10px' }}>
        <UploadDropzone
          companyData={companyData}
          setCompanyData={setCompanyData}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
        />
      </Grid.Col>
      <Grid.Col lg={6} md={12} style={{ padding: '10px' }}>
        <XhtmlPreviews files={uploadedFiles} />
      </Grid.Col>
    </Grid>
  );
}

export default MainContent;
