import { Text, Group } from '@mantine/core';

interface DataRowProps {
  label: string;
  data: string | number;
}

const DataRow: React.FC<DataRowProps> = ({ label, data }) => {
  return (
    <Group position="apart" spacing="lg">
      <div>
        <Text size="sm" weight={400} data-testid={'label'}>
          {label}:
        </Text>
      </div>
      <div>
        <Text weight={400} size="sm" data-testid={'data'}>
          {data}
        </Text>
      </div>
    </Group>
  );
};

export default DataRow;
