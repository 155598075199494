import { Button, Group, Stack, Text, useMantineTheme } from '@mantine/core';
import {
  IconUpload,
  IconFile,
  IconX,
  IconFileAnalytics,
  IconFileOff,
  IconFileUpload
} from '@tabler/icons';
import { Dropzone, FileWithPath } from '@mantine/dropzone';
import { useState } from 'react';
import instance from '../../axiosApi';
import DataRow from '../DataRow/DataRow';
import Break from '../Break';

interface UploadDropzoneProps {
  companyData: CompanyData;
  setCompanyData: (companyData: CompanyData) => void;
  uploadedFiles: FileWithPath[];
  setUploadedFiles: (uploadedFiles: FileWithPath[]) => void;
}
/**
 * UploadDropzone is a functional component that allows users to upload financial reports.
 *
 * @param {UploadDropzoneProps} props - The props of the component.
 * @param {CompanyData} props.companyData - The data of the company that is obtained from the uploaded file.
 * @param {Function} props.setCompanyData - A function that sets the company data.
 * @param {FileWithPath[]} props.uploadedFiles - An array of the uploaded files.
 * @param {Function} props.setUploadedFiles - A function that sets the uploaded files.
 *
 * @returns {JSX.Element} - Renders the UploadDropzone component.
 *
 * @example
 * <UploadDropzone
 *   companyData={companyData}
 *   setCompanyData={setCompanyData}
 *   uploadedFiles={uploadedFiles}
 *   setUploadedFiles={setUploadedFiles}
 * />
 */

function UploadDropzone(props: UploadDropzoneProps): JSX.Element {
  const theme = useMantineTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [error, setError] = useState('');

  function handleReject() {
    setIsRejected(true);
    console.log('rejected files');
  }
  function handleOnDrop(files: FileWithPath[]) {
    props.setCompanyData({
      companyName: '',
      companyId: '',
      revenue: {
        currentYear: 0,
        previousYear: 0
      },
      growth: 0
    });
    setIsRejected(false);
    console.log('files', files);
    props.setUploadedFiles(files);
  }

  const handleUpload = async () => {
    setIsLoading(true);
    setError('');
    const formData = new FormData();
    formData.append('file', props.uploadedFiles[0]);
    try {
      const response = await instance.post('/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      props.setCompanyData(response.data);
    } catch (error) {
      console.error(error);
      setError('An error occurred while analysing the file. ' + error);
    }
    setIsLoading(false);
  };
  return (
    <Stack>
      <Dropzone
        data-id={'Upload-Dropzone'}
        loading={isLoading}
        onDrop={handleOnDrop}
        onReject={() => handleReject()}
        maxSize={1024 * 1024 * 5} // Max size of 5MB
        maxFiles={1}
        accept={{ 'application/xhtml+xml': ['.ixbrl'] }}>
        <Group position="center" spacing="xl" style={{ minHeight: 220, pointerEvents: 'none' }}>
          <Dropzone.Accept>
            <IconUpload
              size={50}
              stroke={1.5}
              color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
            />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX
              size={50}
              stroke={1.5}
              color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
            />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <IconFileUpload size={50} stroke={1.5} />
          </Dropzone.Idle>

          <div>
            <Text size="xl" inline>
              Drop a financial report here.
            </Text>
            <Text size="sm" color="dimmed" inline mt={7}>
              Attach one IXBRL(.xhtml) file.
            </Text>
          </div>
        </Group>
        {props.uploadedFiles.length > 0 ? (
          <Group align="left">
            <IconFile size={20} stroke={1.5} />
            <Text size="xs" color="dimmed" inline mt={7}>
              File Attached: {props.uploadedFiles[0].name}
            </Text>
          </Group>
        ) : (
          <Group align="left">
            <IconFileOff size={20} stroke={1.5} />
            <Text size="sm" color="dimmed" inline mt={7}>
              No file attached
            </Text>
          </Group>
        )}
        {isRejected ? (
          <Group align="left">
            <IconX
              size={20}
              stroke={1.5}
              color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
            />
            <Text size="sm" color="dimmed" inline mt={7}>
              File(s) Rejected - Incorrect type, size or count.
            </Text>
          </Group>
        ) : (
          <Text size="sm" color="dimmed" inline mt={7} />
        )}
      </Dropzone>
      {error ? ( // if error is not empty
        <Text size="sm" color="red" inline mt={7}>
          {error}
        </Text>
      ) : (
        <Text size="sm" color="dimmed" inline mt={7} />
      )}
      {props.uploadedFiles.length > 0 ? (
        <Button
          compact
          variant="gradient"
          leftIcon={<IconFileAnalytics size={20} stroke={1.5} />}
          loading={isLoading}
          onClick={handleUpload}>
          Analyse File
        </Button>
      ) : (
        <Button
          compact
          variant="gradient"
          leftIcon={<IconFileAnalytics size={20} stroke={1.5} />}
          loading={isLoading}
          disabled
          onClick={handleUpload}>
          Analyse File
        </Button>
      )}

      <DataRow label="Company name" data={props.companyData.companyName} />

      <DataRow label="Company Id" data={props.companyData.companyId} />

      <DataRow
        label="Revenue Current Year"
        data={
          '£' +
          props.companyData.revenue.currentYear.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
      />

      <DataRow
        label="Revenue Previous Year"
        data={
          '£' +
          props.companyData.revenue.previousYear.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
      />
      <Break height={2.5} />
      <Group position="center" spacing="lg">
        {props.companyData.companyName === '' ? (
          <Text weight={400} size="sm">
            {' '}
          </Text>
        ) : (
          <Text weight={400} size="sm">
            {props.companyData.companyName}'s revenue has grown by {props.companyData.growth}% over
            the past two reporting periods.
          </Text>
        )}
      </Group>
      <Break height={2.5} />
    </Stack>
  );
}

export default UploadDropzone;
