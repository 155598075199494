import MainContent from './components/MainContent';
import ColorSchemeToggle from './components/ColorSchemeToggle/ColorSchemeToggle';
import { useState } from 'react';
import { themeDark, themeLight } from './themes';
import { AppShell, Footer, MantineProvider, Stack } from '@mantine/core';
import Break from './components/Break';

function App() {
  const [theme, setTheme] = useState(themeDark);
  const toggleTheme = (value: any) => {
    const nextColorScheme = value || (theme === themeDark ? themeLight : themeDark);
    setTheme(nextColorScheme);
  };
  return (
    <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
      <AppShell
        footer={
          <Footer height={25} p="md">
            {' '}
          </Footer>
        }>
        <>
          <Stack align="center">
            <ColorSchemeToggle toggleTheme={toggleTheme} />
          </Stack>
          <Break />
          <MainContent />
        </>
      </AppShell>
    </MantineProvider>
  );
}

export default App;
